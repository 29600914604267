<template>
  <div class="products category-med">
    <v-container class="pb-1">
      <Breadcrumb :items="breadcrumb" />
      <CategoryTitle :category="category" :count="count" height="107px" />
    </v-container>
    <v-container
      v-if="headerProposals.length > 0"
      :fluid="isFluid"
      class="pt-0 pb-5"
    >
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        :autoplay="true"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <v-container class="pt-0">
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        :entityProductCards="entityProductCards"
        @productsCount="updateCount"
      />
    </v-container>

    <v-container
      v-if="footerProposals.length > 0"
      :fluid="isFluid"
      class="mt-16 pb-10"
    >
      <component
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        :autoplay="true"
        paginationClass="pagination-footer"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

import { mapProposal, mapProposalComponent } from "~/service/ebsn";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb,
    ProductListSlider,
    CategoryHeader: () => import("@/components/proposal/CategoryHeader.vue"),
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  data() {
    return { count: -1 };
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    ...mapProposal({
      entityProductCards: "product_card"
    })
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
