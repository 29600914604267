<template>
  <v-container class="mb-1 pl-0">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="subCategory in categories"
        :key="subCategory.categoryId"
      >
        <v-card
          elevation="0"
          :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
        >
          <v-img
            :src="
              subCategory.metaData.category_info &&
              subCategory.metaData.category_info.THUMB
                ? subCategory.metaData.category_info.THUMB
                : '/no-icon.png'
            "
            :alt="subCategory.name"
            width="175"
            height="175"
            class="center-img mt-2"
          ></v-img>
          <v-card-title>{{ subCategory.name }}</v-card-title>
        </v-card>
      </swiper-slide>
    </swiper>
    <div
      class="swiper-pagination swiper-pagination-first-category"
      slot="pagination"
      v-if="categories.length > 5"
    ></div>
  </v-container>
</template>

<style lang="scss" scoped>
.swiper-slide {
  width: initial;
}
.swiper-pagination-firstCategory {
  bottom: -20px;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  justify-content: center;
}

.v-card {
  width: 215px;
  height: 250px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .v-card__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    word-break: keep-all;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<script>
export default {
  name: "CategoryMainSlider",
  props: {
    categories: { type: Array, required: true },
    autoplay: { type: Boolean, default: false }
  },
  data() {
    return {
      swiperOption: {
        autoplay: this.autoplay,
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-first-category",
          clickable: true
        }
      }
    };
  },
  methods: {
    async navTo(slug) {
      this.$router.push({
        name: "Category",
        params: { pathMatch: slug }
      });
    }
  }
};
</script>
