<template>
  <div class="category-main">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <v-container>
      <Breadcrumb :items="breadcrumb" />

      <CategoryTitle :category="category" />

      <CategoryMainSlider :categories="category.children" :autoplay="true" />
    </v-container>
    <v-container v-if="headerProposals.length > 0">
      <component
        :is="headerMode"
        :title="headerTitle"
        :autoplay="true"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>

    <v-container :fluid="isFluid">
      <div>
        <component
          v-if="footer1Proposals.length > 0"
          :key="category.categoryId + '_footer1'"
          :is="footer1Mode"
          :title="footer1Title"
          :autoplay="true"
          :proposals="footer1Proposals"
          paginationClass="pagination-footer-1"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
        <ProductListSlider
          v-else
          :key="category.categoryId + '_footer1'"
          :layout="25"
          :categoryId="category.categoryId"
          :promo="true"
          :autoplay="true"
          :title="'Offerte imperdibili in ' + category.name"
          :paginationClass="'swiper-pagination-offerte'"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <template #title>
            <div class="proposal-title-wrapper primary">
              <h2 class="proposal-title text-uppercase mb-3">
                Offerte imperdibili in {{ category.name }}
              </h2>
            </div>
          </template>
        </ProductListSlider>
      </div>
      <div class="mb-10">
        <component
          v-if="footer2Proposals.length > 0"
          :key="category.categoryId + '_footer2'"
          :is="footer2Mode"
          :title="footer2Title"
          :proposals="footer2Proposals"
          :autoplay="true"
          paginationClass="pagination-footer-2"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
        <ProductListSlider
          v-else
          :key="category.categoryId + '_footer2'"
          :title="'In evidenza in ' + category.name"
          :layout="27"
          :categoryId="category.categoryId"
          :autoplay="true"
          :paginationClass="'swiper-pagination-evidenza'"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <template #title>
            <div class="proposal-title-wrapper secondary lighten-1">
              <h2 class="proposal-title text-uppercase mb-3">
                In evidenza in {{ category.name }}
              </h2>
            </div>
          </template>
        </ProductListSlider>
      </div>
    </v-container>
    <!-- todo slider solo da noi, da chiedere info al cliente -->
  </div>
</template>
<style scoped lang="scss">
.proposal-productlist-slider {
  margin-top: 10px;
}
.slider.product-slider {
  margin-bottom: 25px;
  margin-top: 10px;
}
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";

import { mapProposalComponent } from "~/service/ebsn";

import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",
          clickable: true
        }
      }
    };
  },
  components: {
    CategoryTitle,
    ProductListSlider,
    // ProposalProductListSlider,
    CategoryMainSlider,
    Breadcrumb,
    CategoryHeader: () => import("@/components/proposal/CategoryHeader.vue"),
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      footer1: "footer",
      footer2: "footer_2"
    }),
    description() {
      // lo setto da content perché description altrimenti va in conflitto con categoryTitle?
      return get(this.category, "metaData.category_info.CONTENT", null);
    },
    headerImage() {
      return get(this.category, "metaData.category_info.HEADER_ICON", null);
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
