var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-leaflet"},[_c('v-container',{staticClass:"pb-1"},[_c('Breadcrumb',{attrs:{"items":_vm.breadcrumbs}}),_c('CategoryTitle',{attrs:{"category":_vm.category,"selectedCategory":_vm.selectedCategory,"count":_vm.count,"height":"107px"}})],1),(_vm.headerProposals.length > 0)?_c('v-container',{staticClass:"pt-0 pb-5",attrs:{"fluid":_vm.isFluid}},[_c(_vm.headerMode,{tag:"component",attrs:{"title":_vm.headerTitle,"proposals":_vm.headerProposals,"autoplay":true,"paginationClass":"pagination-header","cols":1,"sm":2,"md":2,"lg":2}})],1):_vm._e(),_c('v-container',[(!_vm.categoryId)?_c('v-row',_vm._l((_vm.category.children),function(subCategory){return _c('v-col',{key:subCategory.categoryId,attrs:{"cols":"6","sm":"4","md":"3","lg":"2"}},[_c('v-card',{attrs:{"outlined":"","height":"100%","to":{
            name: 'Category',
            params: {
              pathMatch: _vm.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          }}},[_c('v-img',{staticClass:"center-img mt-3 mx-3",attrs:{"contain":"","src":subCategory.metaData &&
                subCategory.metaData.category_info &&
                subCategory.metaData.category_info.THUMB,"alt":subCategory.name,"height":"130"}}),_c('v-card-title',{staticClass:"category-block-title"},[_vm._v(_vm._s(subCategory.name))])],1)],1)}),1):_c('ProductListGrid',{key:_vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hidePromoFilter":true,"hideFilterString":"Categorie"},on:{"productsCount":_vm.updateCount}})],1),(_vm.footerProposals.length > 0)?_c('v-container',{staticClass:"mt-16 pb-10",attrs:{"fluid":_vm.isFluid}},[_c(_vm.footerMode,{tag:"component",attrs:{"title":_vm.footerTitle,"proposals":_vm.footerProposals,"autoplay":true,"paginationClass":"pagination-footer","cols":"auto","sm":"auto","md":"auto","lg":"auto"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }