<template>
  <div class="products category-leaflet">
    <v-container class="pb-1">
      <Breadcrumb :items="breadcrumbs" />
      <CategoryTitle
        :category="category"
        :selectedCategory="selectedCategory"
        :count="count"
        height="107px"
      />
    </v-container>

    <v-container
      v-if="headerProposals.length > 0"
      :fluid="isFluid"
      class="pt-0 pb-5"
    >
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        :autoplay="true"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>

    <v-container>
      <v-row v-if="!categoryId">
        <v-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
        >
          <v-card
            outlined
            height="100%"
            :to="{
              name: 'Category',
              params: {
                pathMatch: category.slug
              },
              query: { categoryId: subCategory.categoryId }
            }"
          >
            <v-img
              contain
              :src="
                subCategory.metaData &&
                  subCategory.metaData.category_info &&
                  subCategory.metaData.category_info.THUMB
              "
              :alt="subCategory.name"
              height="130"
              class="center-img mt-3 mx-3"
            ></v-img>
            <v-card-title class="category-block-title">{{
              subCategory.name
            }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <ProductListGrid
        v-else
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="category.categoryId"
        :hidePromoFilter="true"
        hideFilterString="Categorie"
        @productsCount="updateCount"
      />
    </v-container>

    <v-container
      v-if="footerProposals.length > 0"
      :fluid="isFluid"
      class="mt-16 pb-10"
    >
      <component
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        :autoplay="true"
        paginationClass="pagination-footer"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.category-block-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
  justify-content: center;
  word-break: break-word;
}
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";

import { mapProposalComponent } from "~/service/ebsn";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return { categoryId: null, count: null };
  },
  components: {
    CategoryTitle,
    ProductListGrid,
    Breadcrumb,
    CategoryHeader: () => import("@/components/proposal/CategoryHeader.vue"),
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
